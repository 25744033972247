import { cloneDeep } from 'lodash-es'
import { createPaginatedStore } from '@/utils/store'
import http from '@/utils/http'
import { getQueryParams, IQueryParams } from '@/utils/query-params'
import { IPaginatedCustomState } from '@/types/customState'

const store = createPaginatedStore({
  fetchById: async (id) => {
    const { data: connectedStores } = await http.get(`/v1/admin/connected-stores/${id}`)

    return connectedStores
  }
}, {
  state: {
    textFilter: '',
    statusFilter: 'initiated',
    sortBy: '',
    sortDirection: 'DESC'
  },

  mutations: {
    SET_TEXT_FILTER: (state: IPaginatedCustomState, value: string) => {
      state.textFilter = value
    },

    SET_STATUS_FILTER: (state: IPaginatedCustomState, value: string) => {
      state.statusFilter = value
    },

    SET_SORT: (state: IPaginatedCustomState, value: string) => {
      state.sortBy = value
    },

    SET_SORT_DIRECTION: (state: IPaginatedCustomState, value: 'DESC' | 'ASC') => {
      state.sortDirection = value
    }
  },

  actions: {
    fetch: async ({ commit, state }: any, data: { page: number | null; userId: string }) => {
      commit('START_FETCHING')

      const params: IQueryParams = getQueryParams(data, state)

      commit('SET_PAGE', params.page)

      const { data: connectedStores } = await http.get('/v1/admin/connected-stores', {
        params: {
          ...params
        }
      })

      commit('FETCH_SUCCESS', connectedStores)

      return connectedStores
    },

    patchConnectedStore: async (_: any, data: { id: string; installLink?: string | undefined; apiKey?: string | undefined; apiSecretKey?: string | undefined }) => {
      const { data: connectedStores } = await http.patch(`/v1/admin/connected-stores/${data.id}`, { data })

      return connectedStores
    }
  }
})

export default {
  ...store,
  state: () => {
    return cloneDeep(store.state)
  }
}
