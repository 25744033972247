import http from '@/utils/http'

interface ChartJs {
  isLoading: boolean;
  data: object;
}

interface DashboardData {
  orders: number | null;
  invoices: number | null;
  users: number | null;
  supportRequst: number | null;
  products: number | null;
  stores: number | null;
  faqCategories: number | null;
  pages: number | null;
  productCategories: number | null;
  worker: {
    actived: number | null;
    failed: number | null;
  };
};

interface DashboardState {
  selectedMerchantOrUser: number | null;
  selectedWorkerJob: number | null;
  activeFilterInvoice: string;
  activeFilterOrder: string;
  isFirstLoad: boolean;
  charts: {
    users: ChartJs;
    worker: ChartJs;
    invoices: ChartJs;
    orders: ChartJs;
  };
  data: DashboardData;
};

export default {
  namespaced: true,

  state: {
    selectedMerchantOrUser: null,
    selectedWorkerJob: null,
    activeFilterInvoice: 'years',
    activeFilterOrder: 'years',
    isFirstLoad: true,
    charts: {
      users: {
        isLoading: true,
        data: {}
      },
      worker: {
        isLoading: true,
        data: {}
      },
      invoices: {
        isLoading: true,
        data: {}
      },
      orders: {
        isLoading: true,
        data: {}
      }
    },
    data: {
      orders: null,
      invoices: null,
      users: null,
      supportRequst: null,
      products: null,
      stores: null,
      faqCategories: null,
      pages: null,
      productCategories: null,
      worker: {
        actived: null,
        failed: null
      }
    }
  },

  mutations: {
    SET_DASHBOARD_DATA: (state: DashboardState, data: DashboardData) => {
      state.data = data
    },

    SET_GRAPH_ORDERS: (state: DashboardState, data: { labels: []; data: []}) => {
      state.charts.orders.data = {
        labels: data.labels,
        datasets: [{
          label: '$',
          data: data.data,
          fill: true,
          borderColor: 'rgb(54, 185, 204)',
          backgroundColor: 'rgba(54, 185, 204, 0.4)',
          tension: 0.1
        }]
      }
    },

    SET_GRAPH_INVOICES: (state: DashboardState, data: { labels: []; data: []}) => {
      state.charts.invoices.data = {
        labels: data.labels,
        datasets: [{
          label: '$',
          data: data.data,
          fill: true,
          borderColor: 'rgb(28, 200, 138)',
          backgroundColor: 'rgba(28, 200, 138, 0.4)',
          tension: 0.1
        }]
      }
    },

    SET_GRAPH_WORKER: (state: DashboardState, data: { data: []}) => {
      state.charts.worker.data = {
        datasets: [
          {
            data,
            backgroundColor: ['#f6c23e', '#f63e3e'],
            hoverOffset: 2
          }
        ]
      }
    },

    SET_GRAPH_USERS: (state: DashboardState, data: { data: []}) => {
      state.charts.users.data = {
        datasets: [
          {
            data,
            backgroundColor: ['#4e73df', '#36b9cc'],
            hoverOffset: 2
          }
        ]
      }
    },

    SELECT_MERCHANT_USER: (state: DashboardState, selectedMerchantOrUser: number) => {
      state.selectedMerchantOrUser = selectedMerchantOrUser
    },

    SELECT_WORKER_JOB: (state: DashboardState, selectedWorkerJob: number) => {
      state.selectedWorkerJob = selectedWorkerJob
    }
  },

  actions: {
    async fetchData ({ commit }: any): Promise<void> {
      try {
        const { data: items } = await http.get('/v1/admin/dashbaord')
        commit('SET_DASHBOARD_DATA', items)
      } catch (error) {
        console.error('[DASHBOARD]', error)
      }
    },

    async fetchGraphUsers ({ commit, state }: any): Promise<void> {
      state.charts.users.isLoading = true

      try {
        const { data } = await http.post('/v1/admin/dashbaord/graph', { type: 'users' })
        commit('SET_GRAPH_USERS', data)

        state.charts.users.isLoading = false
      } catch (error) {
        console.error('[DASHBOARD]', error)
      }
    },

    async fetchGraphWorker ({ commit, state }: any): Promise<void> {
      state.charts.worker.isLoading = true

      try {
        const { data } = await http.post('/v1/admin/dashbaord/graph', { type: 'worker' })
        commit('SET_GRAPH_WORKER', data)

        state.charts.worker.isLoading = false
      } catch (error) {
        console.error('[DASHBOARD]', error)
      }
    },

    async fetchGraphInvoices ({ commit, state }: any, activeFilter: string): Promise<void> {
      state.charts.invoices.isLoading = true

      state.activeFilterInvoice = activeFilter || state.activeFilterInvoice

      try {
        const { data } = await http.post('/v1/admin/dashbaord/graph', { type: 'invoices', filter: state.activeFilterInvoice })
        commit('SET_GRAPH_INVOICES', data)

        state.charts.invoices.isLoading = false
      } catch (error) {
        console.error('[DASHBOARD]', error)
      }
    },

    async fetchGraphOrders ({ commit, state }: any, activeFilter: string): Promise<void> {
      state.charts.orders.isLoading = true

      state.activeFilterOrder = activeFilter || state.activeFilterOrder

      try {
        const { data } = await http.post('/v1/admin/dashbaord/graph', { type: 'orders', filter: state.activeFilterOrder })
        commit('SET_GRAPH_ORDERS', data)

        state.charts.orders.isLoading = false
      } catch (error) {
        console.error('[DASHBOARD]', error)
      }
    },

    async 'fetchAll' ({ dispatch, state }: any): Promise<void> {
      await dispatch('fetchData')
      await dispatch('fetchGraphUsers')
      await dispatch('fetchGraphWorker')
      await dispatch('fetchGraphInvoices')
      await dispatch('fetchGraphOrders')

      setTimeout(async () => {
        state.isFirstLoad = false
        dispatch('fetchAll')
      }, 40000)
    }
  }
}
