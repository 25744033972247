import { findIndex, filter, orderBy } from 'lodash-es'
import { createCollectionStore } from '@/utils/store'
import http from '@/utils/http'
import { move } from '@/utils/move'

export default createCollectionStore({
  fetch: async () => {
    const { data: categories } = await http.get('/v1/admin/faq-categories')

    return categories
  },

  create: async (payload) => {
    const { data: category } = await http.post('/v1/admin/faq-categories', payload)

    return category
  },

  update: async (payload) => {
    const { data: category } = await http.patch(`/v1/admin/faq-categories/${payload.id}`, payload)

    return category
  },

  delete: async (id) => {
    await http.delete(`/v1/admin/faq-categories/${id}`)
  },

  fetchById: async (id) => {
    const { data } = await http.get(`/v1/admin/faq-categories/${id}`)

    return data
  }
}, {
  state: {
    textFilter: ''
  },

  mutations: {
    SET_TEXT_FILTER: (state: any, value: string) => {
      state.textFilter = value
    },

    ADD_ITEM: (state: any, value: { faqCategoryId: string }) => {
      state.itemsById[value.faqCategoryId].faqItems.push(value)
    },

    EDIT_ITEM: (state: any, value: { faqCategoryId: string; id: string }) => {
      const index = findIndex(state.itemsById[value.faqCategoryId].faqItems, (e: { id: string }) => e.id === value.id, 0)
      Object.assign(state.itemsById[value.faqCategoryId].faqItems[index], value)
    },

    REMOVE_ITEM: (state: any, value: { id: string; faqCategoryId: string }) => {
      let faqItems = filter(state.itemsById[value.faqCategoryId].faqItems, (e: { id: string }) => e.id !== value.id)
      faqItems = orderBy(faqItems, ['order'], ['asc'])

      faqItems.forEach((element, index) => {
        element.order = index + 1
      })

      state.itemsById[value.faqCategoryId].faqItems = faqItems
    },

    ORDER_ITEMS: (state: any, value: { id: string; oldIndex: number; newIndex: number }) => {
      let faqItems = orderBy(state.itemsById[value.id].faqItems, ['order'], ['asc'])

      faqItems = move(faqItems, value.oldIndex, value.newIndex)

      faqItems.forEach((element, index) => {
        element.order = index + 1
      })

      state.itemsById[value.id].faqItems = faqItems
    }
  },

  actions: {
    createItem: async ({ commit, getters }: any, { form, id }: { form: object; id: string }) => {
      const { data: item } = await http.post(`/v1/admin/faq-categories/${id}/item`, {
        ...form,
        order: getters.getDetailsById(id).faqItems.length + 1
      })

      commit('ADD_ITEM', item)
    },

    editItem: async ({ commit }: any, form: { id: string; faqCategoryId: string }) => {
      const { data: item } = await http.patch(`/v1/admin/faq-categories/${form.faqCategoryId}/item/${form.id}`, { ...form })

      commit('EDIT_ITEM', item)
    },

    deleteItem: async ({ commit, getters }: any, item: { id: string; faqCategoryId: string }) => {
      await http.delete(`/v1/admin/faq-categories/${item.faqCategoryId}/item/${item.id}`)

      commit('REMOVE_ITEM', { id: item.id, faqCategoryId: item.faqCategoryId })

      await http.patch(`/v1/admin/faq-categories/${item.faqCategoryId}/items/order`, {
        faqItems: getters.getDetailsById(item.faqCategoryId).faqItems
      })
    },

    orderItems: async ({ commit, getters }: any, { id, oldIndex, newIndex }: { id: string; oldIndex: number; newIndex: number }) => {
      commit('ORDER_ITEMS', { id, oldIndex, newIndex })

      await http.patch(`/v1/admin/faq-categories/${id}/items/order`, {
        faqItems: getters.getDetailsById(id).faqItems
      })
    }
  }
})
