<template>
  <drawer
    :open="isMenuOpen"
    position="left"
    @close="closeMenu"
    headerClass='menu-header'
    :image="require('@/assets/images/logo/logo-le-bon-panier-inline.svg')"
  >
    <div class="drawer-body">
      <div id="mobile-menu-wrap">
        <div class="slicknav_menu">
          <nav class="slicknav_nav slicknav_hidden">
            <ul class="navbar-nav">
              <slot />
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </drawer>
</template>

<script>
import { get } from 'lodash-es'
import Drawer from '@/components/common/Drawer'

export default {
  components: {
    Drawer
  },
  computed: {
    isMenuOpen () {
      return this.$store.state.isMenuOpen
    }
  },
  watch: {
    $route (to, from) {
      if (get(to, 'name') !== get(from, 'name') && this.isMenuOpen) {
        this.closeMenu()
      }
    }
  },
  methods: {
    closeMenu () {
      this.$store.commit('CLOSE_MENU')
    }
  },
  created () {
    window.addEventListener('resize', this.closeMenu)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.closeMenu)
  }
}
</script>

<style lang="scss" scoped>
::v-deep .menu-header {
  background-color: #010E1F;
  color: white;
  border: none !important;

  img {
    filter: invert(1);
  }
}
.drawer-body {
  padding: 0 !important;
}
.small-bold {
  font-size: 0.9rem;
  font-weight: 700;
}
.nav-item {
  background-color: #f3f3f3;
  a {
    display: flex;
    width: 100%;
    padding: 10px 24px !important;
    border-bottom: 1px solid #ECECEC;
  }

  .nav-item  {
    width: 100%;
    padding: 2px 0;
    background-color: #fff;
    text-indent: 10px;
  }
}
</style>
