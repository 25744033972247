import { createCollectionStore } from '@/utils/store'
import http from '@/utils/http'

export default createCollectionStore({
  create: async (payload) => {
    const { data: userRole } = await http.post('/v1/admin/user-roles', payload)

    return userRole
  },

  update: async (payload) => {
    const { data: userRole } = await http.patch(`/v1/admin/user-roles/${payload.id}`, payload)

    return userRole
  }
}, {
  actions: {
    list: async ({ commit }: any, { roleName }: { roleName: string }) => {
      commit('START_FETCHING')

      try {
        const { data } = await http.get(`/v1/admin/user-roles/list/${roleName}`)

        commit('FETCH_SUCCESS', data)
        return data
      } catch (error) {
        commit('FETCH_FAILURE')
        throw error
      }
    }
  }
})
