import { cloneDeep } from 'lodash-es'
import { createPaginatedStore } from '@/utils/store'
import http from '@/utils/http'
import { IPaginatedState } from '@/utils/store/types'

type StoreState = IPaginatedState & { textFilter: string }

const store = createPaginatedStore({}, {
  state: {
    textFilter: ''
  },

  mutations: {
    SET_TEXT_FILTER: (state: StoreState, value: string) => {
      state.textFilter = value
    },
    SET_STORE_IS_SUSPENDED: (state: StoreState, { storeId, isSuspended }: { storeId: string; isSuspended: boolean}) => {
      state.paginatedData.data = state.paginatedData.data.map((item) => {
        if (storeId === item.id) {
          return { ...item, isSuspended }
        }

        return { ...item }
      })
    }
  },

  actions: {
    fetch: async ({ commit, state }: any, data: { page: number | null }) => {
      commit('START_FETCHING')

      let params = {
        page: !data.page ? state.page : data.page,
        perPage: state.perPage
      }

      if (state.textFilter) {
        params = { ...params, text: state.textFilter } as any
      }

      commit('SET_PAGE', params.page)

      const { data: stores } = await http.get('/v1/admin/stores', {
        params
      })

      commit('FETCH_SUCCESS', stores)

      return stores
    },

    fetchByUser: async ({ commit, state }: any, { page, user }: { page: number | null; user: string }) => {
      let params = {
        page: !page ? state.page : page,
        perPage: state.perPage
      }

      if (state.textFilter) {
        params = { ...params, text: state.textFilter } as any
      }

      commit('SET_PAGE', params.page)

      const { data: stores } = await http.get(`/v1/admin/stores/user/${user}`, {
        params
      })

      commit('FETCH_SUCCESS', stores)

      return stores
    },

    editStatus: async ({ commit }: any, { storeId, isSuspended, reason }: { storeId: string; isSuspended: boolean; reason?: string }) => {
      const { data: store } = await http.patch(`/v1/admin/stores/${storeId}/status`, {
        isSuspended,
        reason
      })

      commit('SET_STORE_IS_SUSPENDED', { storeId: store.id, isSuspended: store.isSuspended })

      return store
    }
  }
})

export default {
  ...store,
  state: () => {
    return cloneDeep(store.state)
  }
}
