import { cloneDeep } from 'lodash-es'
import { createPaginatedStore } from '@/utils/store'
import http from '@/utils/http'

const store = createPaginatedStore({}, {
  state: {
    textFilter: ''
  },

  mutations: {
    SET_TEXT_FILTER: (state: any, value: string) => {
      state.textFilter = value
    }
  },

  actions: {
    fetch: async ({ commit, state }: any, data: { page: number | null }) => {
      commit('START_FETCHING')

      let params = {
        page: !data.page ? state.page : data.page,
        perPage: state.perPage
      }

      if (state.textFilter) {
        params = { ...params, text: state.textFilter } as any
      }

      commit('SET_PAGE', params.page)

      const { data: users } = await http.get('/v1/admin/users', {
        params
      })

      commit('FETCH_SUCCESS', users)

      return users
    },

    fetchByStore: async ({ commit, state }: any, { page, store }: { page: number | null; store: string }) => {
      let params = {
        page: !page ? state.page : page,
        perPage: state.perPage
      }

      if (state.textFilter) {
        params = { ...params, text: state.textFilter } as any
      }

      commit('SET_PAGE', params.page)

      const { data: users } = await http.get(`/v1/admin/users/store/${store}`, {
        params
      })

      commit('FETCH_SUCCESS', users)

      return users
    }
  }
})

export default {
  ...store,
  state: () => {
    return cloneDeep(store.state)
  }
}
