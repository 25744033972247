<template>
  <div class="navigation">
    <navigation-item
      v-for="(item, key) in items"
      :key="key"
      :categorie="item.categorie"
      :links="item.links"
      :is-visible="item.isVisible"
    />
  </div>
</template>

<script>
import NavigationItem from './NavigationItem'

export default {
  components: {
    NavigationItem
  },
  data () {
    return {
      items: [
        {
          links: [
            { to: '/', label: 'Tableau de bord' }
          ]
        },
        {
          categorie: 'Finances',
          links: [
            { to: '/orders', label: 'Commandes' },
            { to: '/invoices', label: 'Factures' }
          ],
          isVisible: this.$route.meta.group === 'finances'
        },
        {
          categorie: 'Membres',
          links: [
            { to: '/stores', label: 'Boutiques' },
            { to: '/users', label: 'Utilisateurs' }
          ],
          isVisible: this.$route.meta.group === 'membres'
        },
        {
          categorie: 'Catégories',
          links: [
            { to: '/faq-categories', label: 'FAQ' },
            { to: '/product-categories', label: 'Produits' }
          ],
          isVisible: this.$route.meta.group === 'categories'
        },
        {
          categorie: 'Administration',
          links: [
            { to: '/products', label: 'Produits' },
            { to: '/connected-stores', label: 'Boutiques externes' },
            { to: '/pages', label: 'Pages' },
            { to: '/support/requests', label: 'Demandes de support' },
            { to: '/contacts', label: 'Contacts' }
          ],
          isVisible: this.$route.meta.group === 'administration'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation {
  background: #354052;
  color: rgba(255, 255, 255, 0.877);
  background-color: #010E1F;
  background-size: cover;

  .nav {
    .nav-link {
      color: rgba(255, 255, 255, 0.7);
      font-weight: 500;
      font-size: 15px;
      border-left: 3px solid transparent;
      padding: .7rem 1.5rem;
      display: inline-flex;
      align-items: center;

      &:hover,
      &.active {
        background: #0f1d2d;
      }

      &.active {
        color: rgba(255, 255, 255, 0.9);
        border-left: 3px solid #fff;
      }

      .icon {
        margin-right: 16px;
        color: rgba(255, 255, 255, 0.3);
        width: 18px;
        height: 18px;
      }
    }
  }
  .nav-title {
    padding: 0.5rem 1rem;
    border-left: 3px solid transparent;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.77rem;
    margin: 0;
    border-top: 1px solid #0f1d2d;
    border-bottom: 1px solid #0f1d2d;
  }
}
@media (max-width: 767px) {
  .navigation {
    min-height: 100vh;
  }
}
</style>
