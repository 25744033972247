import { filter, orderBy } from 'lodash-es'
import { createCollectionStore } from '@/utils/store'
import http from '@/utils/http'
import { ProductCategories } from '@/types/productCategory'

interface FetchResponse {
  data: ProductCategories;
}

export default createCollectionStore({
  fetch: async () => {
    const { data: categories }: FetchResponse = await http.get('/v1/product-categories/flat')
    return orderBy(categories, ['order'], ['asc'])
  }
}, {
  getters: {
    getCategoriesByParent: (state: any) => (parentId: string) => {
      return filter(state.collection, { parentId })
    }
  }
})
