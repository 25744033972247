<template>
  <div class="table-responsive">
    <table class="collection-table table" :class="{ 'border-top-none': noTopBorder, 'table-hover': hover }">
      <thead v-if="showHeader">
      <tr>
        <th v-for="col in cols" :class="[col.headerClass, col.isSortable ? 'cursor' : 'no-cursor']" :key="`header-col-${col.property}`" @click.prevent="sortBy(col)">
          <slot v-if="col.headerCheckbox" name='selectRow'></slot>
          <template v-if="(col.isSortable && !onlyDisplaySortCol) || (onlyDisplaySortCol && sort === col.property)">
            <fa-icon class="fa" :class="{ 'sort-disable': sort !== col.property }" :icon="['fas', sort === col.property && sortDirection === 'ASC' ? 'chevron-down' : 'chevron-up']"></fa-icon>
          </template>
          {{ col.header }}
        </th>
        <th v-if="showActions"></th>
      </tr>
      </thead>
        <draggable
          v-if="draggable"
          v-model="draggableList"
          @change="handleChangeOrder"
          tag="tbody"
        >
          <row v-for="item in listItems"
              :key="item[keyName]"
              :cols="cols"
              :object="item"
              :on-edit="onEdit"
              :on-select="onSelect"
              :on-delete="onDelete"
              :on-trigger="onTrigger"
              :action-dropdown="actionDropdown"
              @action="handleAction"
              :custom-actions="getCustomActions(item)"
              :default-actions="defaultActions"
              :show-actions="showActions"
              :link="getLink(item)"
          ></row>
        </draggable>
      <tbody v-else class="table-body">
        <row v-for="item in listItems"
            :key="item[keyName]"
            :cols="cols"
            :object="item"
            :on-edit="onEdit"
            :on-select="onSelect"
            :on-delete="onDelete"
            :on-trigger="onTrigger"
            :action-dropdown="actionDropdown"
            @action="handleAction"
            :custom-actions="getCustomActions(item)"
            :default-actions="defaultActions"
            :show-actions="showActions"
            :link="getLink(item)"
        ></row>
        <tr v-if="!listItems.length" class="bg-white">
          <td :colspan="cols.length">
            <empty-state :src="emptyImgSrc" :message="emptyMessage" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import Row from './CollectionTableRow'
import EmptyState from '@/components/common/EmptyState'

export default {
  components: {
    EmptyState,
    Row,
    Draggable
  },
  data () {
    return {
      draggableList: []
    }
  },
  watch: {
    listItems: {
      immediate: true,
      handler (list) {
        this.draggableList = list
      }
    }
  },
  props: {
    listItems: {
      type: Array,
      required: true,
      default () {
        return []
      }
    },
    cols: {
      type: Array,
      required: true,
      default () {
        return []
      }
    },
    onSelect: {
      type: Function,
      required: false,
      default: () => null
    },
    onEdit: {
      type: Function,
      required: false,
      default: () => null
    },
    onDelete: {
      type: Function,
      required: false,
      default: () => null
    },
    onTrigger: {
      type: Function,
      required: false,
      default: () => null
    },
    showHeader: {
      type: Boolean,
      required: false,
      default: true
    },
    showActions: {
      type: Boolean,
      required: false,
      default: true
    },
    actionDropdown: {
      type: Boolean,
      required: false,
      default: true
    },
    keyName: {
      type: String,
      required: false,
      default: 'id'
    },
    customActions: {
      type: [Array, Function],
      required: false,
      default () {
        return []
      }
    },
    defaultActions: {
      type: Boolean,
      required: false,
      default: true
    },
    noTopBorder: {
      type: Boolean,
      default: false
    },
    link: {
      type: Function
    },
    hover: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      required: false,
      default: false
    },
    emptyMessage: {
      type: String,
      required: false,
      default: 'Aucune donnée disponible'
    },
    emptyImgSrc: {
      type: String,
      required: false,
      default: require('@/assets/images/icons/empty.svg')
    },
    sort: {
      type: String,
      default: null
    },
    sortDirection: {
      type: String,
      default: 'DESC'
    },
    onlyDisplaySortCol: {
      type: Boolean,
      default: false
    },
    isFetching: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleAction (eventName, object) {
      this.$emit(eventName, object)
    },
    getCustomActions (object) {
      return (typeof this.customActions === 'function')
        ? this.customActions(object) : this.customActions
    },
    getLink (object) {
      return (typeof this.link === 'function')
        ? this.link(object) : null
    },
    handleChangeOrder ({ moved: { oldIndex, newIndex } }) {
      this.$emit('order', { oldIndex, newIndex })
    },
    sortBy (col) {
      if (!col.isSortable) {
        return
      }

      const direction = this.sortDirection === 'DESC' ? 'ASC' : 'DESC'

      this.$emit('sortBy', { property: col.property, direction })
    }
  }
}
</script>

<style lang="scss">
.collection-table {
  margin-bottom: 0;

  .cursor {
    cursor: pointer;
  }

  .no-cursor {
    cursor: no-drop;
  }

  .sort-disable {
    color: grey;
  }

  &.border-top-none {
    thead {
      th {
        border-top: 0;
      }
    }
  }

  th {
    font-size: 14px;
    white-space: nowrap;
  }

  .table-body {
    font-size: 14px;

    td {
      vertical-align: middle;
    }
  }

  .col-action {
    width: 16px;
  }
}
</style>
