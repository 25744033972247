import { createCollectionStore } from '@/utils/store'
import http from '@/utils/http'

export default createCollectionStore({
  fetch: async () => {
    const { data: contacts } = await http.get('/v1/admin/contact-messages')

    return contacts
  },

  delete: async (id) => {
    await http.delete(`/v1/admin/contact-messages/${id}`)
  },

  fetchById: async (id) => {
    const { data } = await http.get(`/v1/admin/contact-messages/${id}`)

    return data
  }
}, {
  state: {
    textFilter: '',
    statusFilter: 'not_read'
  },

  mutations: {
    SET_TEXT_FILTER: (state: any, value: string) => {
      state.textFilter = value
    },

    SET_STATUS_FILTER: (state: any, value: string) => {
      state.statusFilter = value
    },

    MARK: (state: any, id: string) => {
      state.collection.map((item: any) => {
        if (item.id === id) {
          item.isRead = !item.isRead
        }
        return item
      })
    }
  },

  actions: {
    mark: async ({ commit }: any, data: { id: string; isRead: boolean }) => {
      try {
        await http.patch(`/v1/admin/contact-messages/${data.id}/mark`, { isRead: data.isRead })

        commit('MARK', data.id)
      } catch (error) {
        console.error('Mark as read / unread')
      }
    }
  }
})
