import { createCollectionStore } from '@/utils/store'
import http from '@/utils/http'

export default createCollectionStore({
  fetch: async () => {
    const { data: pages } = await http.get('/v1/admin/pages')

    return pages
  },

  create: async (payload) => {
    const { data: page } = await http.post('/v1/admin/pages', payload)

    return page
  },

  update: async (payload) => {
    const { data: page } = await http.patch(`/v1/admin/pages/${payload.id}`, payload)

    return page
  },

  delete: async (id) => {
    await http.delete(`/v1/admin/pages/${id}`)
  },

  fetchById: async (id) => {
    const { data } = await http.get(`/v1/admin/pages/${id}`)

    return data
  }
}, {
  state: {
    textFilter: ''
  },

  mutations: {
    SET_TEXT_FILTER: (state: any, value: string) => {
      state.textFilter = value
    }
  }
})
