<template>
  <div class="app-layout">
    <menu-drawer class="ztop">
      <navigation />
    </menu-drawer>
    <sidebar class="d-none d-md-block"></sidebar>
    <div class="main-body-container">
      <topbar></topbar>
      <div class="main-body-content">
        <slot></slot>
      </div>
      <env-banner />
    </div>
    <v-modal-slot></v-modal-slot>
    <v-zoom-image />
  </div>
</template>

<script>
import MenuDrawer from '@/components/nav/MenuDrawer'
import Navigation from '@/components/nav/Navigation'
import Sidebar from '@/components/nav/Sidebar'
import Topbar from '@/components/nav/Topbar'
import EnvBanner from '@/components/common/EnvBanner'

export default {
  components: {
    EnvBanner,
    Sidebar,
    Topbar,
    MenuDrawer,
    Navigation
  }
}
</script>

<style scoped lang="scss">
.canvas__open {
  color: #fff;
  position: relative;
  z-index: 9999999;
}

.app-body {
  position: relative;
  min-height: 100vh;
}

.main-body-container {
  padding-left: 270px;
  min-height: 100vh;
}

.main-body-content {
  display: flex;
  flex: 1;
  min-height: calc(100vh - 65px);
  position: relative;
  min-width: 500px; // TODO: responsive :-)
}

@media (max-width: 767px) {
  .app-layout {
    display: flex;
    flex-direction: column;
  }

  .onboarding-sidebar {
    width: 100vw;
    min-height: auto;
  }

  .main-body-container {
    padding-left: 0;
    min-height: calc(100vh - 353px);
  }

  .main-body-content {
    min-width: 100vw;
    min-height: auto;
  }
}
</style>
