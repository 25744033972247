import { orderBy } from 'lodash-es'
import { createCollectionStore } from '@/utils/store'
import http from '@/utils/http'
import { move } from '@/utils/move'
import { removeKey } from '@/utils/removeKey'

export default createCollectionStore({
  fetch: async () => {
    const { data: categories } = await http.get('/v1/admin/product-categories')

    return categories
  },

  create: async (payload) => {
    const { data: category } = await http.post('/v1/admin/product-categories', payload)

    return category
  },

  update: async (payload) => {
    const { data: category } = await http.patch(`/v1/admin/product-categories/${payload.id}`, payload)

    return category
  },

  delete: async (id) => {
    await http.delete(`/v1/admin/product-categories/${id}`)
  },

  fetchById: async (id) => {
    const { data } = await http.get(`/v1/admin/product-categories/${id}`)

    return data
  }
}, {

  mutations: {
    ORDER_CHILDREN: (state: any, value: { id: string; oldIndex: number; newIndex: number }) => {
      let children = orderBy(state.itemsById[value.id].children, ['order'], ['asc'])

      children = move(children, value.oldIndex, value.newIndex)

      children.forEach((element, index) => {
        element.order = index + 1
      })

      state.itemsById[value.id].children = children
    }
  },

  actions: {
    orderChildrenItems: async ({ commit, getters, dispatch }: any, { id, oldIndex, newIndex }: { id: string; oldIndex: number; newIndex: number }) => {
      commit('ORDER_CHILDREN', { id, oldIndex, newIndex })

      await dispatch('orderChildren', { children: getters.getDetailsById(id).children })
    },

    orderChildren: async (_: any, { children }: { children: Record<string, any>[] }) => {
      await http.put('/v1/admin/product-categories/order', { children: removeKey(children, 'children') })
    },

    fetchChildren: async ({ dispatch }: any, { parentId }: { parentId: string }) => {
      let children: Record<string, any>[] = []
      const parent = await dispatch('getParent', { parentId })

      if (parent) {
        children = parent.children
      }

      return children
    },

    getParent: async (_: any, { parentId }: { parentId: string | null }) => {
      if (!parentId) {
        return null
      }

      try {
        const { data } = await http.get(`/v1/admin/product-categories/${parentId}`)

        return data
      } catch (error) {
        return null
      }
    },

    getOrder: async ({ state, dispatch }: any, { parentId }: { parentId: string | null }) => {
      if (!parentId) {
        return state.collection.length + 1
      }

      try {
        const parent = await dispatch('getParent', { parentId })

        return parent ? parent.children.length + 1 : 1
      } catch (error) {
        return 1
      }
    }
  }
})
