import { VueConstructor } from 'vue'
import DynamicForm from './components/DynamicForm.vue'
import DynamicOutput from './components/DynamicOutput.vue'
import inputMixin from './components/inputs/inputMixin'
import outputMixin from './components/outputs/outputMixin'
import { FormsPluginObject, Component, InputTypeConfig } from './types'

type TypesMap = { [name: string]: Component }

const inputTypes: TypesMap = {
  checkbox: () => import('./components/inputs/CheckboxInput.vue'),
  checkboxes: () => import('./components/inputs/CheckboxesInput.vue'),
  email: () => import('./components/inputs/EmailInput.vue'),
  multiselect: () => import('./components/inputs/MultiSelectInput.vue'),
  number: () => import('./components/inputs/NumberInput.vue'),
  password: () => import('./components/inputs/PasswordInput.vue'),
  radios: () => import('./components/inputs/RadiosInput.vue'),
  select: () => import('./components/inputs/SelectInput.vue'),
  textarea: () => import('./components/inputs/TextareaInput.vue'),
  text: () => import('./components/inputs/TextInput.vue')
}

const outputTypes: TypesMap = {
  text: () => import('./components/outputs/TextOutput.vue')
}

const registerType = (type: InputTypeConfig) => {
  inputTypes[type.name] = type.input

  if (type.output) {
    outputTypes[type.name] = type.output
  }
}

const VueForms: FormsPluginObject = {
  registerTypes (types) {
    if (Array.isArray(types)) {
      for (const type of types) {
        registerType(type)
      }
    } else {
      registerType(types)
    }
  },

  getInputComponent (name) {
    return inputTypes[name] ? inputTypes[name] : inputTypes.text
  },

  getOutputComponent (name) {
    return outputTypes[name] ? outputTypes[name] : outputTypes.text
  }
}

export const FormsPlugin = {
  install: (Vue: VueConstructor) => {
    Vue.component('v-dynamic-form', DynamicForm)
    Vue.component('v-dynamic-output', DynamicOutput)

    Vue.prototype.$forms = VueForms
    Vue.forms = VueForms
  }
}

export {
  inputMixin,
  outputMixin
}
