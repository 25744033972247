import axios from 'axios'
import config from '@/config'
import firebase from '@/firebase'
import store from '../store'

const http = axios.create({
  baseURL: config.api.baseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
})

// Inject JWT token if user is authenticated

http.interceptors.request.use(async (request) => {
  const user = firebase.auth().currentUser
  const token = user ? await user.getIdToken() : null

  if (token) {
    request.headers.Authorization = `Bearer ${token}`
  }

  return request
}, error => Promise.reject(error))

http.interceptors.response.use(response => Promise.resolve(response), (error) => {
  if (error.response && error.response.status && error.response.status === 401) {
    store.dispatch('logout')
  }

  /* eslint-disable prefer-promise-reject-errors */
  return Promise.reject(error)
})

export default http
