import { cloneDeep } from 'lodash-es'
import { createPaginatedStore } from '@/utils/store'
import http from '@/utils/http'
import { getQueryParams, IQueryParams } from '@/utils/query-params'
import { IPaginatedCustomState } from '@/types/customState'

const store = createPaginatedStore({
  fetchById: async ({ id }) => {
    const { data: supportRequest } = await http.get(`/v1/support/requests/${id}`)

    return supportRequest
  }
}, {
  state: {
    textFilter: '',
    statusFilter: '',
    assignFilter: '',
    waitingReplyFilter: '',
    sourceFilter: '',
    activeFilter: '',
    sortBy: '',
    sortDirection: 'DESC'
  },

  mutations: {
    SET_TEXT_FILTER: (state: IPaginatedCustomState, value: string) => {
      state.textFilter = value
    },

    SET_STATUS_FILTER: (state: IPaginatedCustomState, value: string) => {
      state.statusFilter = value
    },

    SET_ASSIGN_FILTER: (state: IPaginatedCustomState, value: string) => {
      state.assignFilter = value
    },

    SET_WAITING_REPLY_FILTER: (state: IPaginatedCustomState, value: string) => {
      state.waitingReplyFilter = value
    },

    SET_ACTIVE_FILTER: (state: IPaginatedCustomState, value: string) => {
      state.activeFilter = value
    },

    SET_SOURCE_FILTER: (state: IPaginatedCustomState, value: string) => {
      state.sourceFilter = value
    },

    SET_SORT: (state: IPaginatedCustomState, value: string) => {
      state.sortBy = value
    },

    SET_SORT_DIRECTION: (state: IPaginatedCustomState, value: 'DESC' | 'ASC') => {
      state.sortDirection = value
    }
  },

  actions: {
    fetch: async ({ commit, state }: any, data: { page: number | null; userId: string }) => {
      commit('START_FETCHING')

      const params: IQueryParams = getQueryParams(data, state)

      commit('SET_PAGE', params.page)

      const { data: supportRequests } = await http.get('/v1/support/requests', {
        params: {
          userId: data.userId,
          ...params
        }
      })

      commit('FETCH_SUCCESS', supportRequests)

      return supportRequests
    },

    updateStatus: async (_: any, payload: { id: string; isClosed: boolean }) => {
      const { data } = await http.patch(`/v1/admin/support/requests/${payload.id}/status`, payload)

      return data
    },

    assign: async (_: any, payload: { id: string; assignId: string | null }) => {
      const { data } = await http.patch(`/v1/admin/support/requests/${payload.id}/assign`, payload)

      return data
    }
  }
})

export default {
  ...store,
  state: () => {
    return cloneDeep(store.state)
  }
}
