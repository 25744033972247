<template>
  <div class="form-group" :class="className">
    <label :for="inputId" v-if="displayLabel">{{ inputLabel }} <span v-if="isRequired" class="text-danger">*</span></label>

    <small v-if="helpText && helpText.length > 0 && helpPosition === 'top'" :id="helpTextId" class="form-text text-muted mb-3">
      {{ helpText }}
    </small>

    <component
      :is="inputType"
      :input-id="inputId"
      :help-text-id="helpTextId"
      :label="inputLabel"
      :options="options"
      :params="params"
      :placeholder="inputPlaceholder"
      :value="value"
      @value-change="handleChange"
      :has-error="hasError"
      v-bind="bindings"
    />

    <small v-if="!hasError && helpText && helpText.length > 0 && helpPosition === 'bottom'" :id="helpTextId" class="form-text text-muted">
      {{ helpText }}
    </small>

    <div v-if="hasError" class="invalid-feedback">{{ errorFeedback }}</div>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    },
    locale: {
      type: String,
      required: true,
      default: 'en'
    },
    value: {
      required: false,
      default: null
    },
    errors: {
      type: [Array, String],
      default () {
        return []
      }
    }
  },
  computed: {
    displayLabel () {
      return this.field.type !== 'checkbox'
    },
    inputType () {
      const type = this.field.type ? this.field.type : 'text'
      return this.$forms.getInputComponent(type)
    },
    className () {
      return this.field.className ? this.field.className : null
    },
    inputId () {
      return `${this.field.name}-input`
    },
    inputLabel () {
      if (this.field.label && typeof this.field.label === 'string') {
        return this.field.label
      } else if (typeof this.field.label === 'object' && this.field.label[this.locale]) {
        return this.field.label[this.locale]
      }
      return null
    },
    inputPlaceholder () {
      if (this.field.placeholder && typeof this.field.placeholder === 'string') {
        return this.field.placeholder
      } else if (typeof this.field.placeholder === 'object' && this.field.placeholder[this.locale]) {
        return this.field.placeholder[this.locale]
      }
      return null
    },
    helpText () {
      if (this.field.helpText && typeof this.field.helpText === 'string') {
        return this.field.helpText
      } else if (typeof this.field.helpText === 'object' && this.field.helpText[this.locale]) {
        return this.field.helpText[this.locale]
      }
      return null
    },
    helpTextId () {
      return this.helpText ? `${this.field.name}-help` : null
    },
    helpPosition () {
      return this.field.helpPosition === 'top' ? 'top' : 'bottom'
    },
    options () {
      const hasOptions = ['checkboxes', 'multiselect', 'radios', 'select']

      return this.field.type && hasOptions.indexOf(this.field.type) > -1
        ? this.computeOptions() : null
    },
    params () {
      return this.field.params ? this.field.params : null
    },
    isRequired () {
      return Boolean(this.field.required)
    },
    hasError () {
      return (Array.isArray(this.errors) && this.errors.length > 0) || (typeof this.errors === 'string' && this.errors.length > 0)
    },
    errorFeedback () {
      return Array.isArray(this.errors) ? this.errors[0] : this.errors
    },
    bindings () {
      if (this.field.props) {
        return this.field.props
      }

      return {}
    }
  },
  methods: {
    computeOptions () {
      const options = []

      if (Array.isArray(this.field.options)) {
        this.field.options.forEach((option) => {
          const label = typeof option.label === 'object' ? option.label[this.locale] : option.label

          options.push({
            value: option.value,
            label
          })
        })
      }

      return options
    },
    handleChange (evt) {
      this.$emit('input', evt)
    }
  }
}
</script>

<style lang="scss" scoped>
.invalid-feedback {
  display: block;
  font-size: 13px;
}
</style>
