import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import dashboard from './modules/dashboard'
import invoices from './modules/invoices'
import orders from './modules/orders'
import faqCategories from './modules/faq-categories'
import pages from './modules/pages'
import productCategories from './modules/product-categories'
import productCategoriesFlat from './modules/product-categories-flat'
import users from './modules/users'
import stores from './modules/stores'
import supportRequests from './modules/supportRequests'
import userRoles from './modules/user-roles'
import contacts from './modules/contacts'
import products from './modules/products'
import productEditor from './modules/productEditor'
import variantTypes from './modules/variantTypes'
import connectedStores from './modules/connectedStores'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMenuOpen: false
  },
  mutations: {
    TOGGLE_MENU (state) {
      state.isMenuOpen = !state.isMenuOpen
    },
    CLOSE_MENU (state) {
      state.isMenuOpen = false
    }
  },
  actions: {
  },
  modules: {
    auth,
    dashboard,
    users,
    faqCategories,
    productCategories,
    productCategoriesFlat,
    invoices,
    pages,
    stores,
    supportRequests,
    orders,
    userRoles,
    contacts,
    products,
    productEditor,
    variantTypes,
    connectedStores
  }
})
