import firebase from '@/firebase'
import http from '@/utils/http'
import config from '@/config'
import router from '@/router'

interface AuthState {
  authenticated: boolean;
  user: UserObject;
  userProviderId: string | null;
  userFetched: boolean;
}

interface UserObject {
  email?: string;
  firstname?: string;
  lastname?: string;
  role?: string;
  id?: string;
}

export default {
  state: {
    authenticated: false,
    user: {},
    userId: null,
    userFetched: false
  },

  getters: {
    hasAdminPrivilege: (state: AuthState) => {
      const role = state.user && state.user.role ? state.user.role : null
      return role === 'superadmin' || role === 'admin'
    },
    isAuthenticated: (state: AuthState) => {
      return state.authenticated
    },
    getUserProviderId: (state: AuthState) => {
      return state.userProviderId
    },
    getUserInitials: (state: AuthState) => {
      if (state.user) {
        const firstLetter = state.user.firstname ? state.user.firstname.substr(0, 1) : ''
        const lastLetter = state.user.lastname ? state.user.lastname.substr(0, 1) : ''
        return `${firstLetter}${lastLetter}`
      }
      return ''
    }
  },

  mutations: {
    USER_AUTHENTICATED: (state: AuthState, uid: string) => {
      state.authenticated = true
      state.userProviderId = uid
    },

    USER_LOGGED_OUT: (state: AuthState) => {
      state.authenticated = false
      state.user = {}
      state.userFetched = false
      state.userProviderId = null
    },

    CURRENT_USER_FETCHED: (state: AuthState, user: UserObject) => {
      state.user = user
      state.userFetched = true
    }
  },

  actions: {
    async login ({ commit, dispatch, getters }: any, { username, password }: { username: string; password: string }): Promise<void> {
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      const { user } = await firebase.auth().signInWithEmailAndPassword(username, password)

      if (user) {
        localStorage.setItem(config.auth.userKeyName, user.uid)

        const fetchedUser = await dispatch('fetchCurrentUser')

        if (fetchedUser && getters.hasAdminPrivilege) {
          commit('USER_AUTHENTICATED', user.uid)
        } else {
          localStorage.removeItem(config.auth.userKeyName)
          await firebase.auth().signOut()
          throw new Error('forbidden')
        }
      }
    },

    async logout ({ commit }: any) {
      await firebase.auth().signOut()
      localStorage.removeItem(config.auth.userKeyName)
      commit('USER_LOGGED_OUT')
      router.push(config.auth.guestRedirectPath)
    },

    async fetchCurrentUser ({ commit, state }: any): Promise<UserObject | null> {
      if (state.userProviderId) {
        try {
          const { data: user } = await http.get(`/v1/users/provider-id/${state.userProviderId}`)

          const { data: userRole } = await http.get(`/v1/users/${user.id}/role`)
          const role = userRole ? userRole.roleName : null

          commit('CURRENT_USER_FETCHED', { ...user, role })

          return { ...user, role }
        } catch (error) {
          return null
        }
      }

      return null
    }
  }
}
