<template>
  <div class="text-center py-5">
    <img class="empty-image" :src="src" alt="" />
    <label class="d-block mt-3 lead">{{ message }}</label>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: false,
      default: 'Aucune donnée disponible'
    },
    src: {
      type: String,
      required: false,
      default: require('@/assets/images/icons/empty.svg')
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-image {
  width: 80px;
  max-width: 80px;
  height: auto;
  display: inline;
  margin: 0 auto;
}
</style>
