<template>
  <div>
    <div v-if="categorie">
      <div
        :aria-expanded="visible"
        aria-controls="collapse-4"
        @click.prevent="visible = !visible"
        class="nav-title"
      >
        <fa-icon v-if="visible" :icon="['fas', 'chevron-down']"></fa-icon>
        <fa-icon v-else :icon="['fas', 'chevron-right']"></fa-icon>
        {{ categorie }}
      </div>

      <b-collapse v-model="visible">
        <nav class="nav flex-column" v-for="(link, key) in links" :key="key">
          <router-link class="nav-link" :to="link.to" active-class="active" >{{ link.label }}</router-link>
        </nav>
      </b-collapse>
    </div>
    <div v-else>
      <nav class="nav flex-column" v-for="(link, key) in links" :key="key">
        <router-link class="nav-link" :to="link.to" :active-class="activeClass(link.to)" >{{ link.label }}</router-link>
      </nav>
    </div>
  </div>
</template>

<script>
import { BCollapse, VBToggle } from 'bootstrap-vue'

export default {
  components: {
    BCollapse
  },
  directives: {
    'b-toggle': VBToggle
  },
  watch: {
    isVisible: {
      handler (visible) {
        this.visible = visible
      },
      immediate: true
    }
  },
  props: {
    categorie: {
      type: String,
      required: false,
      default: null
    },
    links: {
      type: Array,
      required: false,
      default: () => []
    },
    isVisible: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      visible: false
    }
  },
  methods: {
    activeClass (link) {
      return this.$route.path === link ? 'active' : null
    }
  }
}
</script>

<style lang="scss" scoped>
.nav {
  .nav-link {
    color: rgba(255, 255, 255, 0.7);
    font-weight: 500;
    font-size: 15px;
    border-left: 3px solid transparent;
    padding: .7rem 1.5rem;
    display: inline-flex;
    align-items: center;

    &:hover,
    &.active {
      background: #021938;
    }

    &.active {
      color: rgba(255, 255, 255, 0.9);
      border-left: 3px solid #63B3ED;
    }

    .icon {
      margin-right: 16px;
      color: rgba(255, 255, 255, 0.3);
      width: 18px;
      height: 18px;
    }
  }
}
.nav-title {
  padding: 0.5rem 1rem;
  border-left: 3px solid transparent;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.77rem;
  margin: 8px 0;
  color: rgba(255, 255, 255, 0.61);
  font-size: 1rem;
  cursor: pointer;
}
@media (max-width: 767px) {
  .nav-title {
    margin: 0 !important;
  }
}
</style>
