export const move = (input: Array<any>, from: number, to: number): Array<any> => {
  let numberOfDeletedItem = 1

  const item = input.splice(from, numberOfDeletedItem)[0]

  numberOfDeletedItem = 0

  input.splice(to, numberOfDeletedItem, item)

  return input
}
